export default {
	message: {
		title: "Suivi des colis globals",
		search: "Ai Track",
		carrier: "Identifier les transporteurs automatiquement",
		carrier_name: "Transporteurs",
		carrier_description:
			"Le système identifiera le transporteur correspondant aux numéros de suivi",
		auto_check: "Détection automatique",
		clear_all: "Supprimer tous",
		select_transport_title: "Sélectionner un transporteur",
		all_carrier: "Transporteurs principals globals",
		all_airline: "Tous les compagnies aériennes",
		common_carriers: "Réguliers",
		carrier_page_title: "Suivi de colis",

		not_found_order_no:
			"Nous n'avons pas pu détecter le colis, veuillez vérifiez si le numéro de suivi correpond à votre transporteur",
		order_no_has_shipment:
			"Nous n'avons pas pu détecter un suivi du vol, veuillez vérifier l'information du vol",

		network_error: "Erreur en requête réseau",
		order_no_maybe_carriers:
			"Le numéro de suivi correpond proablement à un des transporteurs suivants",
		your_order_no_exists_many_carriers:
			"Plusieux transporteurs possibles correspondent à votre numéro de suivi",
		tracking_result_title: "Résultat de suivi",
		air_tracking_result_title: "Information de vols",

		copy_link: "Copier le lien",
		copy_detail: "Copier les détails",
		more_detail: "More information",
		end_logistics_number: "Last Mile Tracking No.",
		dispatcher: "Carrier",
		dispatcher_website: "Carrier website",
		phone: "Carrier Tel",
		more_detail_btn_tip: "No more information.",

		// menu
		menu_tracking_no_search: "Suivi de colis",
		menu_history: "L'Historique des suivis",
		menu_log: "Note des mise à jour",

		// /Result tab title
		all: "Tous",
		unknow: "Inconnu",

		tracking_list_empty:
			"Nous n'avons pas pu détecter un enregistrement correspondant",
		air_list_empty: "Aucunes données de vols",

		email_code_send_success: "Le code de vérification a été envoyé",

		// /运输单号校验
		title_input_order_no:
			"Veuiller entrer le numéro de suivi ( de 7 à 50 caractères )",
		tracking_no_duplicate: "Au moins une duplication a été détectée",
		tracking_no_hint:
			"Entrer ou colier des numéros de suivi, jusqu'à 20 au maximum",

		copy_success: "Copié",

		normalCarriers: "Transporteurs logistiques",
		airCarriers: "Opérateurs aériens",
		try_title: "Essayer: ",
		parcel_unit: "Pièces",

		air_no: "Vol",
		air_start_end_station: "Gare de départ / Gare d'arrivée",
		air_maybe_start_time: "L'heure prévue de départ",
		air_maybe_end_time: "L'heure prévue d'arrivée",
		air_start_time: "L'heure de départ du vol",
		air_end_time: "L'heure d'arrivée du vol",
		weight_unit: "Poid / Pièce",

		// login
		login_title: "Se connecter",
		login_register: "Créer votre compte >",
		login_button: "Se connecter",
		login_forget_pwd: "Mot de passe oublié",
		login_password: "Mot de passe",
		login_email: "Adresse e-mail",
		remember_me: "Rester connecté",

		// 忘记密码
		forgot_pwd_title: "Retrouver votre MDP",
		forgot_pwd_code: "Code de vérification",
		forgot_pwd_newPwd: "Nouveau mot de passe",
		forgot_pwd_confirmPwd: "Entrer le mot de passe à nouveau",
		forgot_pwd_ok: "Sûr",
		forgot_pwd_login: "Retour >",
		forgot_pwd_send_code: "Envoyer",
		forgot_pwd_send_second: " secondes",

		// 修改密码
		change_pwd_title: "Modifier le MDP",
		change_pwd_old_pwd: "mot de passe d'origine",
		change_pwd_old_pwd_empty: "Le mot de passe d'origine ne peut pas être vide",
		change_pwd_success: "Réinitialisation du mot de passe terminée",
		change_pwd_button_ok: "Sûr",

		// 注册用户
		register_title: "Créer votre compte",
		register_name: "Identifiant",
		register_ok: "S'inscrire maintenant",
		password_format_wrong:
			"La chaine doit être entre 8 et 16 caractères, avec au moins un chiffre et une lettre, sans espace blanc",
		privacy_agreement_tip:'By registering, you agree to',
		privacy_agreement:'《Aitrack Privacy Policies》',
		user_email_empty: "L'adresse e-mail est obligatoire",
		user_email_validate: "L'adresse e-mail saisie n'est pas valide",
		user_name_empty: "L'identifiant est obligatoire",
		user_email_code_empty: "Le code de vérification est obligatoire",
		user_new_pwd_empty: "Le mot de passe est obligatoire",
		user_confirm_pwd_empty: "Le second mot de passe est obligatoire",
		user_pwd2_empty: "Les mots de passe ne correspondent pas",

		regist_login: "S'inscrire / Se connecter",
		back_home: "Retour à la page d'accueil",
		registration_agreement: "",
		synchronization_time: "Synchroniser les temps：",

		register_success: "Félicitation! Votre compte a été créé avec succès",
		reset_pwd_success: "Votre mot de passe a été réinitialisé",

		// 日期组件
		date_dialog_today: "Aujourd'hui",
		date_dialog_week: "La semaine dernière",
		date_dialog_month: "Le mois dernier",

		// 请求code错误
		request_101_params_error: "Mauvais paramètres à la requête",
		request_103_page_params_error:
			"Mauvais paramètres à la reauête de pagination",
		request_104_no_permission: "L'autorisation d'accès n'est pas suffisante",

		request_portal_102_login_name_exists: "L'identifiant existe déjà",
		request_portal_105_email_exists: "L'adresse e-mail existe déjà",
		request_portal_106_code_exists: "Mauvais code de vérification",
		request_portal_107_login_fail: "Mauvaise adresse e-mail ou mot de passe",
		request_portal_108_email_not_exists: "L'adresse e-mail est inconnue",
		request_portal_109_account_disabled: "Votre compte est désactivé",
		request_portal_110_password_error:
			"Le mot de passe original n'est pas correct",
		request_portal_111_email_exists:
			"Le compte d'e-mail existe déjà, veuillez vous connecter directement ",
		request_portal_112_password_format_wrong:
			"Le format du mot de passe n'est pas correct",

		// 请求code错误-结束

		keyword_search_carriers: "Recherche des tranporteurs par mot clé",

		user_logout: "Se déconnecter",

		table_no: "Numéro de série",
		table_operate: "Actions",
		table_sort_asc: "",
		table_sort_desc: "",
		table_page_total: "total",
		table_page_row: " ",
		table_no_select: "Aucune ligne n'est sélectionnée",
		table_delete: "Supprimer",
		table_delete_rows: "Comfirmez-vous la suppression ?",

		// /按钮文字
		button_confirm_text: "confirmer",
		button_ok_text: "confirmer",
		button_cancel_text: "annuler",

		// /页面-历史运单表格列名

		history_table_tracking_no: "Numéro de suivi",
		// 'history_table_carriers': 'transporteurs',
		history_table_package_status: "L'état du colis",
		history_table_event: "Evénement",
		history_table_event_time: "L'heure d'événement",
		history_table_shipping_update_time: "Heure de MAJ",
		history_table_update_time: "Heure de requête dernière",
		create_time: "L'heure de création",
		history_input_tracking_no_search:
			"Veuiller entrer le numéro de suivi pour la recherche",

		// 页面-更新日志
		log_page_title:
			"Nos services sont disponibles pour plus que 100 transporteurs transfrontaliers",
		log_page_description:
			"Nos services sont déjà disponibles pour USPS, ROYALMAIL, DHL, GLS etc., et nous continuions à simplifier et intégrer le processus, et à établir le partenariat avec plus de transporteurs",

		// 提示
		tooltip_click_copy: "Clique à copier",

		// 首页运输商查询
		home_page_input_search_carrier: "Recherche d'un transporteur",

		// /用户运单查询-批量导入弹框
		user_search_page_dialog_import_batch: "Importer par lots",
		user_search_page_dialog_file_upload: "Clique à télécharger des fichiers",
		user_search_page_dialog_file_format_incorrect:
			"Le format du fichier importé n'est pas correct",
		user_search_page_dialog_step1_click: "1.Clique",
		user_search_page_dialog_step1_download_template: " Télécharger le modèle ",
		user_search_page_dialog_step1_end:
			"Veuiller compléter le relevé d'importation ( vérifier le code du transporteur en cliquant sur une autre feuille )",
		user_search_page_dialog_step2:
			"2.Les numéros en duplication seront suivis et funisionnés à l'original ( 1000 numéros au maximum à la fois )",
		user_search_page_dialog_step3:
			"3.L'informaion n'est pas mise à jour immédiatement suite à l'import, et le processus de suivi est disponible à l'Historique des suivis",
		user_search_page_dialog_select_file:
			"Veuiller sélectionner le ficher d'importation premièrement",
		user_search_page_dialog_upload_success:
			"Le fichier a été téléchargé avec succès, et le processus de suivi est disponible à l'Historique des suivis",
		user_search_page_dialog_upload_fail: "Importation échouée",

		user_search_page_dialog_more_then_100: "1000 numéros au maximum à la fois",

		query_fail_input_verification:
			"Veuiller entrer l'information de vérification",

		// /运单查询页（查询结果页，用户查询页）
		tracking_page_input_post_code:
			"Veuiller entrer le code postal de la destination",
		tracking_page_select_date:
			"Veuiller sélectionner une date de ramasse des colis",
		tracking_page_input_phone:
			"Veuiller entrer le numéro téléphonique du destinataire",
		tracking_page_input_dest: "Veuiller entrer le distinataire",

		// /提交建议
		connect_us_title: "Contacter-nous",
		connect_us_samll_title: "Prendre votre avis de suggestions pour AITrack：",
		connect_us_input_title: "Veuiller entrer un titre",
		connect_us_title_name: "Titre",
		connect_us_title_name_length_2:
			"Le titre doit contenir au moins 2 caractères",
		connect_us_input_suggest: "Veuiller entrer vos suggestions",
		connect_us_your_suggest: "Vos suggestions",
		connect_us_your_suggest_length_10:
			"La suggestion doit contenir au moins 10 caractères",
		connect_us_your_email: "Votre adresse e-mail",
		connect_us_your_phone: "Votre numéro téléphonique",
		connect_us_your_submit_suggest: "Soumettre une suggestion",

		connect_us_suggest1: "Je veux faire déveloper une nouvelle fonctionalité",
		connect_us_suggest2:
			"Je veux faire ajouter des nouveaux transporteurs dans AITrack",
		connect_us_suggest3: "Je veux annoncer un bogue",
		connect_us_suggest4: "Je veux faire des affaires avec AITrack",
		connect_us_suggest5: "D'ailleurs",

		research_btn: "Essayer une nouvelle recherche",

		// 批量导入成功结果展示
		import_excel_result_upload_success:
			"Le fichier a été téléchargé avec succès",
		import_excel_result_success_count: "Nombre des suivis avec succès：",
		import_excel_result_fail_count: "Nombre des suivis en échec：",
		import_excel_result_success_rewrite:
			" suivis existent déjà et sont couverts",
		import_excel_result_error_format: " (Le format n'est pas correct)",
		import_excel_result_view_fail_detail: "Veuiller vérifier le détail d'échec",
		import_excel_result_success_description:
			"et le processus de suivi est disponible à l'Historique des suivis",
		import_excel_result_table_column_row_number:
			"Le nombre des lignes au tableau",
		import_excel_result_table_column_fail_tracking_no:
			"Nombre des suivis en échec",
		import_excel_result_table_column_fail_reason: "La raison d'échec",

		// Excel导入错误提示
		import_excel_result_validate_tracking_no:
			"Le numéro de suivi est obligatoire",
		import_excel_result_validate_carrier_code:
			"Le code du transporteur n'est pas correct",
		import_excel_result_validate_post_code:
			"Code postal de la destination (obligatoire)",
		import_excel_result_validate_date: "Date de la livraison (obligatoire)",
		import_excel_result_validate_phone:
			"Numéro téléphonique du destinataire (obligatoire)",
		import_excel_result_validate_dest: "Destination (obligatoire)",

		import_excel_ok:
			"Bien reçu, nous vous remercions pour votre aide et support",

		// /2022-04-08 后面追加新的翻译
		// /全部
		event_names_all: "Tous",
		event_names_all_des: "",

		// /未知
		event_names_unknown: "Inconnue",
		event_names_unknown_des: "",

		// /未查询到
		event_names_not_found: "Nous n'avons",
		event_names_not_found_des: "",

		// /运输途中
		event_names_in_transit: "En transit",
		event_names_in_transit_des: "",

		// /到达待取
		event_names_arrival_for_pickup: "A la consigne",
		event_names_arrival_for_pickup_des: "",

		// /投递失败
		event_names_delivery_failed: "Non livré",
		event_names_delivery_failed_des: "",

		// /成功签收
		event_names_signed_successfully: "Livré",
		event_names_signed_successfully_des: "",

		// /可能异常
		event_names_possible_exceptions: "Alarme",
		event_names_possible_exceptions_des: "",

		// /运输过久
		event_names_shipping_too_long: "Expiré",
		event_names_shipping_too_long_des: "",

		// /2022-04-11 后面追加新的翻译
		utc_0: "World time UTC+0",
		utc_1: "East First District UTC+1",
		utc_2: "East Second District UTC+2",
		utc_3: "East Third District UTC+3",
		utc_4: "East Fourth District UTC+4",
		utc_5: "East Fifth District UTC+5",
		utc_5_5: "East Fifth District UTC+5.5",
		utc_6: "East Sixth District UTC+6",
		utc_7: "East Seventh District UTC+7",
		utc_8: "East Eigith District UTC+8",
		utc_9: "East Ninth District UTC+9",
		utc_10: "East Tenth District UTC+10",
		utc_11: "East Eleventh District UTC+11",
		utc_12: "East Twelfth District UTC+12",
		"utc_-1": "West First District UTC-1",
		"utc_-2": "West Second District UTC-2",
		"utc_-3": "West Third District UTC-3",
		"utc_-4": "West Fourth District UTC-4",
		"utc_-5": "West Fifth District UTC-5",
		"utc_-6": "West Sixth District UTC-6",
		"utc_-7": "West Seventh District UTC-7",
		"utc_-8": "West Eigith District UTC-8",
		"utc_-9": "West Ninth District UTC-9",
		"utc_-10": "West Tenth District UTC-10",
		"utc_-11": "West Eleventh District UTC-11",
		"utc_-12": "West Twelfth District UTC-12",
		tracking_time_zone: "Tracking time zone",

		// /2022-04-13
		// /默认标题
		web_title: "Aitrack-Suivi des colis globals",
		web_title_carrier: "Aitrack-Transporteurs principals globals",
		web_title_log: "Aitrack-Note des mise à jour",
		web_title_user_history: "Aitrack-L'Historique des suivis",
		web_title_automatically: "Aitrack-Se connecter",
		web_title_carrier_detail: "Aitrack-Suivi des colis globals",

		// /2022-05-07
		web_title_register: "Aitrack-Register an account",
		web_title_found_pwd: "Aitrack-Forgot password",
		web_title_guest_track: "Aitrack-Waybill tracking",
		web_title_user_track: "Aitrack-Waybill inquiry",

		// /2022-05-09
		web_title_description:
			"Aitrack is a global logistics packages tracking platform. Support USPS, Fedex, DHL, UPS, EMS, GLS and other carriers package tracking.",
		web_title_carrier_description:
			"Aitrack is a global logistics packages tracking platform. Support USPS, Fedex, DHL, UPS, EMS, GLS and other carriers package tracking.",
		web_title_log_description:
			"Aitrack has supported USPS, Fedex, DHL, UPS, EMS, GLS and other carriers package tracking, global cooperative carriers keep adding.",
		web_title_user_history_description:
			"Aitrack helps you track your package status anytime, anywhere.",
		web_title_automatically_description:
			"Welcome to Aitrack! We are committed to providing global users with one-stop inquiry services for international logistics packages.",
		web_title_carrier_detail_description:
			"Aitrack helps you track package status.",
		web_title_register_description: "",
		web_title_found_pwd_description: "",
		web_title_guest_track_description: "",
		web_title_user_track_description: "",

		// /2022-06-09
		user_manager_title: "Account management",
		other_type_login_title: "Bind your third party account",
		user_un_bind_title: "Unbound",
		user_bound_title: "Bound",
		user_remove_bind_title: "Unbind",
		user_info_validate_title: "Information verification",
		user_info_phone: "Cellphone",
		user_info_change_phone: "Change cellphone number",
		user_info_bind_phone: "Bind cellphone number",
		user_info_change_email: "Change email address",
		btn_save: "Save",
		send_email_code_more:
			"Please do not frequently request to send verification codes",

		// /2022-06-10
		change_email_old: "Old email verification",
		change_email_new: "New email verification",
		change_email_btn_next: "Next",

		"request_modify-email_106_error":
			"The verification code for previous email address is incorrect",
		"request_modify-email_107_error":
			"The verification code for new email address is incorrect",
		"request_modify-email_same_email_error":
			"The new email address cannot be the same as the previous one",
		bind_phone_title: "verification cellphone",
		un_validate_phone_title: "Unbind",
		phone_is_empty_title: "Cellphone number cannot be empty",
		validate_phone_error: "Incorrect verification code",
		please_send_code_email:
			"Please check your mailbox to get the verification code",
		validate_card: "Verify your email address",

		interface_security_title: "The key to access subscribe interface",
		reset_security_key: "Renew the key",
		security_key: "Key",

		reset_security_confirm:
			"If you renew the key, the previous key will be invalid. Please confirm if you still want to renew it ?",

		btn_search: "Inquiry",
		your_tracking_no: "Your tracking number",

		vip_tracking_page_url: "URL for your own tracking page",
		vip_tracking_page_url_description:
			"Copy the tracking page link and add it to your store's website",
		h5_not_found_title: "Sorry, the tracking number information was not found",
		h5_not_found_content:
			"Please check the tracking number information, unshipped waybill cannot be tracked",

		save_success: "Operation successfully",

		// /2022-06-23
		user_match_rule_title: "Custom event",
		user_match_rule_keywords_title: "Enter event name and search",
		btn_event_management: "Event management",
		// 'btn_new_match_rule': 'Add Custom Event',
		condition_rule_match_type: "Matching rules",
		condition_trajectory_rule: "Standardize activity",

		history_exist_trackingNo_carrierCode:
			"The carrier information of this Waybill already exists and cannot be changed",

		// /2022-06-27
		event_match_page_select_event: "Select event",
		event_match_page_event_manage: "Event management",
		event_match_page_info:
			"(Note: You can skip the status and search events directly)",
		event_match_page_view_all_event: "View all events",
		event_match_page_view_add_event: "Add custom event",
		event_match_page_view_edit_event: "Edit custom event",

		event_match_page_view_state_lv1: "Primary status",
		event_match_page_view_state_lv2: "Secondary status",
		event_match_page_view_event: "Event",

		event_match_page_view_state_lv1_name: "Primary status name",
		event_match_page_view_state_lv2_name: "Secondary status name",
		event_match_page_view_event_name: "Event name",
		event_match_page_view_status_title: "Enabled status",
		event_match_page_view_begin_date_title: "Valid start date",
		event_match_page_view_end_date_title: "Valid end date",

		event_match_page_add_matching_url_title: "Add rule",
		event_match_page_edit_matching_url_title: "Edit rule",
		waybill_status:"Status",
		additional_waybill_status: "Status",
		model_Tips:'Notice',
		tips_content:'The visitors are limited to a daily inquiry. You can login aitrack to check the waybill status and hope you a better experience in Aitrack. ',
		remark: "Remark",
		full_matching_rule: "Fully match",
		reg_matching_rule: "Regex match",
		matching_rule_title: "Matching rules",
		additional_matching_behavior:'extra action',
		overwrite_delivery_status:'overwrite delivered status',
		front_splicing_event_name:'prepend with event name',
		replace_with_event_name:'replace by event name',
		block_deletion:'delete',
		matching_rule_error_103: "Update failed",
		matching_rule_error_104: "The matching rule has already existed",
		matching_rule_error_105: "The matching rule is not available",

		error_105: "There was a problem with the service, please try again later",
		error_996: "Search failed, please ask customer service for help. ",

		event_match_page_view_begin_date_empty_title:
			"Please select valid start date",
		event_match_page_view_end_date_empty_title: "Please select valid end date",
		event_match_page_view_begin_end_date_error:
			"Valid end date cannot be earlier than the valid start date",

		event_match_page_test_matching: "Test rule",
		event_match_page_test_matching_title: "Regular expression rules test",
		event_match_page_test_trajectory_title: "Tracking information",
		event_match_page_test_input_trajectory_title:
			"Please enter tracking information",
		event_match_page_test_fail: "Track information doesn't match the rule",

		event_match_help:
			"Learning website: <a href='https://www.runoob.com/regexp/regexp-syntax.html' target='_blank'>https://www.runoob.com/regexp/regexp-syntax.html< /a><br/>Simple example:<br/>^ABC matches messages starting with ABC<br/>^ABC$ matches messages ending with ABC<br/>^AB C$ matches messages starting with AB,  ending with C<br/>AB.{0,30} matches messages starting with AB and ending with any character from 0 to 30<br/>.*AB.* matches messages starting with any character, including AB, and ending with any character <br/>",

		// /2022-07-05
		event_info_save_102: "Event name already exists",

		// /2022-07-06
		carrier_full_name: "Carrier name",
		table_edit: "Edit",
		status: "Status",
		status_open: "Enable",
		status_close: "Disable",

		event_match_page_test_success:
			"Track information matches the rule successfully",

		// /2022-07-07
		user_page_timezone_title: "Time display for tracking event",
		user_page_timezone_item_carrier_timezone: "Display by carrier's time zone",
		user_page_timezone_item_user_timeone: "Display by your time zone",

		page_size: "Page size",
		page_jump: "Jump page",

		// /2022-07-20
		user_bi_analysis_page_title: "Delivery analysis",
		all_carrier_title: "All carriers",
		all_country_title: "All countries",

		day_title: "Day",
		week_title: "Week",
		month_title: "Month",

		day: "day", // 单数
		days: "days", // 复数

		quantile_title: "quantile",
		destination_country_title: "Destination country",
		query_time_range_title: "Time horizon",
		tt_days_title: "Due days",
		ntt_days_title: "Shipping so far",
		temp_kpi_title: "Temporary KPI",

		kpi_manage_title: "Standard KPI",
		otd_analysis_title: "On-Time-Delivery analysis",
		otd_tt_analysis_title: "Delivered",
		otd_utt_analysis_title: "Out of delivery",

		standard_title: "Over the benchmark",
		un_standard_title: "Below the benchmark",
		proper_investment_title: "Delivery status",
		delivery_success_rate_title: "Delivery rate",
		proportion_of_investment_days_title: "Duration proportion",
		details_of_completed_investment_title: "Delivered details",
		failure_rate_title: "Failure rate",
		details_of_incomplete_investment_title: "Details of shipping packages",

		proportion_of_up_to_standard_title: "Benchmark percents",
		time_for_reaching_the_standard_title: "Quantile values",
		kpi_all_title: "All KPI",
		kpi_standard_title: "Standard KPI",
		kpi_temporary_title: "Temporary KPI",

		please_select_query_time_range_title:
			"Please enter the start date and end date.",
		query_time_range_end_less_start_title:
			"The end date you entered occurs before the start date.",
		query_days_15_title: "Query has surpassed the 15-day horizon.",
		query_weeks_15_title: "Query has surpassed the 15-week horizon.",
		query_months_15_title: "Query has surpassed the 15-month horizon.",

		temporary_kpi_rate_0_100_title: "Quantile range of temporary KPI: 0-100",

		unsuccessful_delivery_orders_title: "Number of shipping packages",
		unsuccessful_bid_rate_title: "Failure rate",

		successful_delivery_orders_title: "Number of delivered packages",
		successful_bid_rate_title: "Delivery rate",

		country_title: "Country",
		kpi_type_title: "Type of KPI",
		avg_score_title: "Avg quantile",
		avg_time_title: "Avg duration",
		delay_title: "delay",
		delay_more_9_days_title: "Delay of 10 and more days",

		total_votes_title: "Total",
		delivered_votes_title: "Delivered",
		un_delivered_votes_title: "Undelivered",
		delay_votes_title: "Delayed",
		shipped_orders_title: "Number of delivered packages",
		proportion_title: "Proportion",

		total_number_of_waybills_including_undelivered_waybills:
			"Total(include undelivered)",
		total_title: "Accumulation",
		total_tracking_no: "Total",
		unshipped_orders_title: "Undelivered",

		// /标准KPI管理
		kpi_standard_quantile_title: "Quantile",
		quantile_compliance_days: "KPI(days)",
		dialog_standard_KPI_management_title: "Standard KPI management",
		add_kpi: "Add KPI",
		edit_kpi: "Edit KPI",
		import_103: "Please download the latest template.",

		please_input_kpi_quantile_title: "Please enter the quantile.",
		kpi_quantile_0_100_title: "Quantile range of KPI: 0-100",
		please_select_carrier_title: "Please select a carrier.",
		please_select_quantile_day_title: "Please select a benchmark.",

		select_country_title: "Select country",
		please_select_country_title: "Please select country.",
		kpi_quantile_title: "KPI quantile",
		quantile_day_title: "KPI(days)",
		time_range_title: "Time horizon",

		waybill_collection_time: "Waybill collection time",

		within_title: "Within", // 1天内
		more_than_title: "more than", // 1天以上

		country_origin: "The country of origin",
		destination_country: "The country of destination",
		origin_title: "Origin",
		destination_title: "Destination",

		airline_title: "Airline",
		airline_master_number: "Airline master waybill number",
		tracking_number_information: "The tracking number information",
		view_more_tracking_information: "Show more tracking details",

		no_tracking_data: "No tracking result",

		// /2022-07-27
		please_select_1_item: "Please select at least one",
		recipient_phone: "The recipient phone number",
		recipient_email: "The recipient email",
		export_otd_title: "Export delayed & undelivered waybill list",
		select_export_otd_kpi_type_title: "Select the KPI",
		export_delivered: "Export delivered waybill list",
		export_un_delivered: "Export undelivered waybill list",

		// /2022-08-01
		page_user_title: "Basic setting",
		page_user_member_title: "Member account",
		page_user_subscription_title: "My subscription",
		amount_title: "Account",

		tooltip_title: "Note",
		delete_confirm_title: "Delete ",
		add_member: "Add member",
		edit_member: "Edit",
		setting_pwd: "Password",
		auto_pwd: "Auto create",
		define_pwd: "Setting",
		tooltip_pwd:
			"*After the user is successfully created, the account password is sent to the email address by default, and the password can be changed after logging in",

		ddl_clear_all: "Clear",

		select_tt_days_title: "Choose several due days",
		select_ntt_days_title: "Choose several horizons of shipping so far",
		"8_20_length": "8-20 characters",

		request_portal_115_not_activated_wrong:
			"The account is not activated, please contact the staff",
		kpi_exists_override:
			"This standard KPI already exists, do you want to override it ?",

		recharge: "Top-up",
		subscription: "Spend",
		free: "Free",
		deduction: "Deduct",
		date: "Date",
		quota: "Subscription amount",
		type: "Type",
		quota_validity_period: "Valid date",
		remaining_query_quota: "Balance",
		please_choose: "Please select ",
		please_input: "Please enter ",
		not_empty: " is required",
		email: "Email",

		clear: "Clear",

		save_successful: "Saved",
		delete_successful: "Deleted",

		// /2022-08-11
		user_notify_center_title: "Notification center",
		page_user_notify_task_title: "Notification task",
		page_user_notify_template_title: "Notification template",
		add: "Add",

		task_name: "Task",
		trigger_task_event: "Trigger event",
		notify_type: "Channel",
		mail: "Email",
		sms: "SMS",
		notify_event_type: "Trigger event level",
		update_time: "Modification date",
		add_task: "Add task",
		edit_task: "Edit task",

		task_tooltip: "Attention: All sent as default.",
		template: "Template",
		preview: "Preview",
		template_name: "Template name",

		email_template: "Email template",
		add_email_template: "Add email template",
		edit_email_template: "Edit email template",

		sms_template: "SMS template",
		add_sms_template: "Add SMS template",
		edit_sms_template: "Edit SMS template",

		template_desc: "Template description",

		view_url: "Click here to check more waybill details",

		template_content: "Content",

		view: "View",
		edit: "Edit",

		// 2022-08-15
		send_test_sms: "Send test SMS",
		send_test_email: "Send test email",
		send_test_sms_target: "Send test SMS to",
		send_test_email_target: "Send test email to",

		send_test_sms_tooltip:
			"Test SMS that you sent in success will expend your subscription.",
		send_test_email_tooltip:
			"Test email that you sent in success will expend your subscription.",
		send_successful: "Send successfully",

		view_sms_template: "View test SMS",
		view_email_template: "View test email",

		email_subject: "Theme content",

		api_trackings_109:
			"Sorry to tell you that the waybill tracking service is invalid since your subscription has been used up.",
		api_trackings_110:
			"Sorry to tell you that the waybill tracking service is invalid since your subscription has been expired.",

		recommend: "Recommend",
		customize: "Customize",

		exists_template_name: "Duplicated template name",
		exists_task_name: "Duplicated task name",
		join_task_template: "Fail to delete. The template is in use",

		// /2022-08-18
		template_language: "Template language",
		EN: "EN",
		CN: "CN",
		ALL: "ALL",

		user_page_callback_url: "Subscribe callback service", // 订阅回调
		user_page_callback_url_tip:
			"Specify the callback url as you have add callback service. AiTrack will be invoked to pushing the latest tracking data through the API",
		callback_url: "Callback url",
		callback_url_error: "The format of callback url is incorrect.",

		balance_detail: "Stored value",
		subscribe_detail: "Subscription balance",
		balance_yuan: "Stored value",
		operate_time: "Operate time",
		balance_recharge: "Top-up",
		money_yuan: "Expense",

		sms_pay: "SMS consumption",
		change_add: "Increment",
		change_reduce: "Reduction",

		// /2022-08-25
		page_sms_service_title: "SMS service",
		page_sms_service_description:
			"It can provide you with customized SMS notifications, notify recipients to pick up or handle exceptions, etc., to quickly improve the quality of customer service",
		page_sms_service_note:
			"Reminder: Price of SMS may change as supplier varies price without any extra notification .",
		telecom_operators: "Telecommunications suppliers",
		sms_price: "Price (CNY/Message)",
		sms_price_download: "Download the SMS pricing sheet",
		sms_template_note:
			"Reminder: Please pay attention to the content of the SMS template. If the template content is too long, multiple charges may be charged. English letters and numbers are limited to 160 characters per SMS, and the content of SMS containing non-English letters or special characters will be downgraded to 70 characters per SMS. After the limit is exceeded, the content can be sent normally, but an additional SMS fee will be charged. For specific charges, please refer to the SMS sending record page.",
		page_shop_title: "My shops",
		page_shop_tooltip: "Up to 10 shops can be added",
		add_shop: "Add",
		edit_shop: "Edit",
		shop_name: "Shop name",
		shop_type: "Shop type",
		shop_url: "Shop address",
		shop_status: "Shop status",
		shop_delete_title:
			"After deletion, all the waybills in the shop will be cleared and cannot be recovered. Are you sure you want to delete the shop?",
		shop_disable:
			"After deactivation, you will not be able to add a waybill, and the existing waybill will no longer be synchronized with the track. Are you sure you want to deactivate?",
		import_data: "Import",
		synchronous_data: "Sync",
		synchronous_days_range: "Time horizon of imported data",
		shop_save_104: "Parameter is invalid",
		shop_save_105: "The number of shop exceeds the limit",
		shop_save_106: "Sync time exceeds limit",
		shop_save_107: "Shop name is repeated",
		synchronous_data_tooltip:
			"The waybill will be imported within the time range, and the new waybill will be automatically and continuously synchronized. The first synchronization may last from a few minutes to a few days.",
		page_user_sms_detail_title: "SMS expense detail",
		send_time: "Sending time",
		send_status: "Sending status",
		successful: "Succeed",
		failure: "Failed",
		send_country: "Country",
		received_phone: "Phone",
		count_sms: "Quantity of billed SMS",
		money_type: "Currency",
		fee: "Expense",
		with_tracking_no: "Related order number",
		trigger_event_name: "Trigger event",
		between_30_days: "Within 30 days",

		synchronous_now: "Sync",
		api_trackings_108: "Importing data is not allowed for deactivated shop.",
		shop_sync_108: "Data synchronization is not allowed for deactivated shop.",

		page_api_title: "API configuration",
		add_api: "Add API",
		edit_api: "Edit API",
		api_nick_name: "API alias",
		connect_special_api: "Connect to dedicated API",
		URL: "URL",
		language: "Language",
		front_btn: "Previous",
		api_save_105: "The API alias already exists.",
		api_save_106: "The API configuration already exists.",

		enable_url: "Valid URL",
		disable_url: "Invalid URL",
		invalid_query: "Invalid query",
		subscribeBalance_0:
			"Your subscription has been used up, please contact customer service to recharge.",
		insufficient_subscribeBalance: "Insufficient quota",

		api_trackings_101: "Please enter at least one tracking number",
		api_trackings_102: "Too many waybills",
		api_trackings_108_2: "Quota used up",
		wait_query: "Pending",
		money: "CNY",
		api_event_info_delete_105:
			"Delete failed: We couldn't delete the event in use. Please check it in custom event.",
		api_event_info_delete_108:
			"Delete failed: We couldn't delete the event in use. Please check it in notification task.",
	},
};
