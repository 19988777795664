import Vue from "vue";
import webConfig from '@/config/wenConfig';


import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';

// 读缓存默认 语言
let lang = webConfig.getCurrentLang();

// 系统设置
const routes = [
    {
        path: '/:lang',
        name: 'index',
        component: () => import ('../views/Index.vue')
    },
    {
        path: '/privacyAgreement',
        name: 'privacyAgreement',
        component: () => import ('../views/PrivacyAgreement.vue')
    },
    {
        path: '/logic/loginSuccess',
        name: 'loginSuccess',
        component: () => import ('../views/logic/LoginSuccess.vue')
    },
    {
        path: '/:lang/login',
        name: 'login',
        component: () => import ('../views/Login.vue')
    },
    {
        path: '/:lang/forgot',
        name: 'forgot',
        component: () => import ('../views/ForgotPwd.vue')
    }, {
        path: '/:lang/register',
        name: 'register',
        component: () => import ('../views/Register.vue')
    }, {
        path: '/:lang/result/:code/:num',
        name: 'result',
        component: () => import ('../views/Result.vue')
    },{
        path: '/:lang/num/:num',
        name: 'copyNum',
        component: () => import ('../views/Result.vue')
    },{
        path: '/track/:code/:num',
        name: 'track',
        component: () => import ('../views/Result.vue')
    }, {
        path: '/:lang/log',
        name: 'log',
        component: () => import ('../views/Log.vue')
    }, {
        path: '/:lang/smsService',
        name: 'smsService',
        component: () => import ('../views/SmsService.vue')
    }, {
        path: '/:lang/plan',
        name: 'plan',
        component: () => import ('../views/Plan.vue')
    }, {
        path: '/:lang/tenant/:id',
        name: 'tenant',
        component: () => import ('../views/H5Tenant.vue')
    }, {
        path: '/:lang/tenant/:id/:trackingNo',
        name: 'tenantResult',
        component: () => import ('../views/H5TenantResult.vue')
    }, {
        path: '/:lang/aboutUS',
        name: 'aboutUS',
        component: () => import ('../views/AboutUS.vue')
    }, {
        path: '/:lang/carrierList',
        name: 'carrierList',
        component: () => import ('../views/CarrierList.vue')
    }, {
        path: '/:lang/carrier/:id',
        name: 'carrier',
        component: () => import ('../views/Carrier.vue')
    }, { // 配置默认路由
        path: "/", // 路由地址
        name: '/',
        redirect: '/' + lang,
        component: () => import ('../views/Index.vue')
    },
    // 已登录用户菜单
    {
        path: '/:lang/user/search',
        name: 'userSearch',
        component: () => import ('../views/user/Search.vue')
    }, {
        path: '/:lang/user/search/:code/:num',
        name: 'userSearchResult',
        component: () => import ('../views/user/Search.vue')
    }, {
        path: '/:lang/user/history',
        name: 'userHistory',
        component: () => import ('../views/user/History.vue')
    }, {
        path: '/:lang/user/balance',
        name: 'balance',
        component: () => import ('../views/user/Balance.vue')
    }, {
        path: '/:lang/user',
        name: 'user',
        component: () => import ('../views/user/User.vue')
    }, {
        path: '/:lang/user/customer',
        name: 'customer',
        component: () => import ('../views/user/Customer.vue')
    }, {
        path: '/:lang/user/member',
        name: 'member',
        component: () => import ('../views/user/Member.vue')
    }, {
        path: '/:lang/user/balanceOrder/:id',
        name: 'balanceOrder',
        component: () => import ('../views/user/BalanceOrder.vue')
    }, {
        path: '/:lang/user/notify/task',
        name: 'notifyTask',
        component: () => import ('../views/user/NotifyTask.vue')
    }, {
        path: '/:lang/user/notify/template',
        name: 'notifyTemplate',
        component: () => import ('../views/user/NotifyTemplate.vue')
    }, {
        path: '/:lang/user/sms/detail',
        name: 'smsDetail',
        component: () => import ('../views/user/SmsDetail.vue')
    }, {
        path: '/:lang/user/matchRule',
        name: 'matchRule',
        component: () => import ('../views/user/MatchRule.vue')
    }, {
        path: '/:lang/user/shop',
        name: 'shop',
        component: () => import ('../views/user/Shop.vue')
    }, {
        path: '/:lang/user/api',
        name: 'api',
        component: () => import ('../views/user/Api.vue')
    }, {
        path: '/:lang/user/carrierConfig',
        name: 'carrierConfig',
        component: () => import ('../views/user/CarrierConfig.vue')
    }, {
        path: '/:lang/user/biAnalysis',
        name: 'biAnalysis',
        component: () => import ('../views/bi/Analysis.vue')
    }, {
        path: '/:lang/user/biRangeAnalysis',
        name: 'biRangeAnalysis',
        component: () => import ('../views/bi/RangeAnalysis.vue')
    }


];
// 3. 创建路由实例并传递 `routes` 配置。
const router = createRouter({ // 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    // history: createWebHashHistory(), //包含 #
    history: createWebHistory(), //去掉 #
    routes, // `routes: routes` 的缩写
})

export default router;
