import webConfig from '@/config/wenConfig';

export default {
  getBaseUrl() {
    let apiUrl = process.env.VUE_APP_API;
    return apiUrl;
  },

  // 网站地址
  getBaseWebsitUrl() {
    let webUrl = process.env.VUE_APP_WEB_URL;
    return webUrl;
  },

  getWebsitUrl() {
    let webUrl = this.getBaseWebsitUrl();
    return webUrl + webConfig.getCurrentLang() + '/';
  }
}

