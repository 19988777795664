<template>
  <div style="height: 57px;min-height: 57px;"></div>
  <div style="position: fixed;top: 0;left: 0;z-index:2; " class="mainDivWidth">

    <a-layout-header style=" box-shadow: inset 0px -1px 0px 0px #EEEEEE;top: 0px;">

      <!-- Phone -->
      <a-row type=" flex" v-if="isPhone()">
        <a-col flex="100px">
          <div>
            <img src="../../assets/icon_logo.svg" style="color: #1976D2;cursor: pointer;margin-left: 20px;" height="20"
              @click="goHome" />
          </div>
        </a-col>
        <a-col flex="auto">
          <a-row type="flex" justify="end" style="padding-right: 20px;">
            <a-col flex="30px" @click="clickPhoneSearchUI" v-if="showPhoneSerarchUI">
              <i class="iconfont icon-icon_Search" style="fontSize:15px;padding-right: 4px;"></i>
            </a-col>
            <a-col flex="30px">
              <div style="padding-right: 30px;padding-left: 15px;">

                <a-dropdown>
                  <a class="ant-dropdown-link" style="color: black;" @click.prevent>
                    <i class="iconfont icon-language" style="fontSize:15px;padding-right: 4px;"></i>
                  </a>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a href="javascript:;" @click="changeLang('en')">English</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="changeLang('zh')">简体中文</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="changeLang('fr')">Français</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="changeLang('es')">Português</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </a-col>
            <a-col>

            </a-col>
          </a-row>
        </a-col>
      </a-row>

      <!-- PC -->
      <a-row type="flex" v-else>
        <a-col flex="100px">
          <div>
            <img src="../../assets/icon_logo.svg" style="color: #1976D2;cursor: pointer;margin-left: 20px;" height="20"
              @click="goHome" />
          </div>
        </a-col>
        <a-col flex="auto">
          <a-row type="flex" justify="end" style="padding-right: 20px; ">
            <a-col v-for="(item, key) in pageData.menuItems " :key="key">
              <div :class="curMenu == item.path ? 'selectMenuItem' : 'menuItem'"  @click="toURL(item.path)">
                {{ item.name}}
              </div>
            </a-col>

            <!-- 数据分析 -->
            <a-col v-if="logined">
              <div class="menuItem">
                <a-dropdown>
                  <a class="ant-dropdown-link dropdownDiv" style="color: black;" @click.prevent>
                    {{ $t('message.data_analysis') }}
                    <i class="iconfont icon-icon_Arrow1 dropdownRightIcon" ></i>
                  </a>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/user/biAnalysis')">{{
                            t('message.user_bi_analysis_page_title')
                        }}</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/user/biRangeAnalysis')">{{
                            t('message.range_user_bi_analysis_page_title')
                        }}</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </a-col>

            <!-- 功能 -->
            <a-col v-if="logined">
              <div class="menuItem">
                <a-dropdown>
                  <div class="ant-dropdown-link dropdownDiv"  @click.prevent>
                    {{ $t('message.menu_app') }}
                    <i class="iconfont icon-icon_Arrow1 dropdownRightIcon" ></i>
                  </div>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/user/shop')">
                          {{ $t('message.page_shop_title') }}</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/user/notify/task')">
                          {{ $t('message.user_notify_center_title') }}</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/user/matchRule')">
                          {{ $t('message.user_match_rule_title') }}</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/user/api')">{{ $t('message.page_api_title') }}</a>
                      </a-menu-item>
                      <!-- <a-menu-item>
                        <a href="javascript:;" @click="toURL('/user/carrierConfig')">{{ $t('message.page_carrier_config_title') }}</a>
                      </a-menu-item> -->
                      
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </a-col>

            <a-col v-if="logined">
              <div class="menuItem">
                <a-dropdown>
                  <div class="ant-dropdown-link dropdownDiv"  @click.prevent>
                    {{ $t('message.menu_more') }}
                    <i class="iconfont icon-icon_Arrow1 dropdownRightIcon" ></i>
                  </div>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/carrierList')">{{ t('message.carrier_name') }}</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/plan')">{{ t('message.plan_title') }}</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/log')">{{ t('message.menu_log') }}</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/smsService')">{{ t('message.page_sms_service_title')
}}</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="toURL('/aboutUS')">{{ t('message.about_us')
}}</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </a-col>

            <a-col>
              <div style="padding-right: 30px;padding-left: 15px;">
                <a-dropdown>
                  <div class="ant-dropdown-link dropdownDiv"  @click.prevent>
                    <i class="iconfont icon-language" style="fontSize:10px;padding-right: 4px;"></i>
                    {{ langName }}
                    <i class="iconfont icon-icon_Arrow1 dropdownRightIcon" ></i>
                  </div>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a href="javascript:;" @click="changeLang('en')">English</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="changeLang('zh')">简体中文</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="changeLang('fr')">Français</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="changeLang('es')">Português</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </a-col>
            <a-col>
              <div style="padding-right: 10px;" v-if="logined">
                <a-popover placement="bottomLeft" trigger="click" v-model:visible="visibleMenu" :getPopupContainer="
  triggerNode => {
    return triggerNode.parentNode;
  }
">
                  <template #content>
                    <div class="userDiv" style="text-align:center;">
                      <a-row>
                        <a-col :span="24">
                          <div style="padding-top: 20px;height: 80px;"><i class="iconfont icon-icon_touxinag"
                              style="color: #1976D2;font-size: 40px;" /></div>
                          <div style="font-size: 18px;height: 20px;line-height: 20px;margin-top: 5px;">
                            {{ loginedData.storage.loginName }}
                          </div>
                          <div style="height: 20px;line-height: 20px;margin-top: 5px;color: #999;">
                            {{ loginedData.storage.email }}
                          </div>
                          <div style="margin-top: 16px;align-items: center;">
                            <span
                              style="height: 28px;line-height: 28px;background: #F0F0F0;border-radius: 16px;cursor: pointer;padding: 8px 16px;font-size: 12px;"
                              @click="gotoManager">
                              {{ $t('message.user_manager_title') }}
                            </span>
                          </div>
                          <a-divider style="margin-top: 16px;margin-bottom: 8px;" />
                          <div class="menuBtn" @click="changePwd">
                            {{ $t('message.change_pwd_title') }}
                          </div>
                          <div class="menuBtn" @click="userLogout">
                            {{ $t('message.user_logout') }}
                          </div>
                        </a-col>
                      </a-row>
                    </div>
                  </template>


                  <a-row type="flex" style="cursor: pointer;">
                    <a-col flex="32px" style="height: 57px;">
                      <i class="iconfont icon-icon_touxinag" style="color: #1976D2;font-size: 32px;" />
                    </a-col>
                    <a-col flex="auto">
                      <div style="height: 17px;line-height: 17px;margin-top: 10px;padding-left: 8px;">
                        <span>{{ loginedData.storage.loginName }}</span>
                        <br />
                        <span style="font-size: 12px;color: #999;">{{ loginedData.storage.email }}</span>
                      </div>
                    </a-col>
                  </a-row>
                </a-popover>


              </div>
              <div style="padding-right: 10px;" v-else>
                <a-button v-if="!isLogin" type="primary" shape="round" @click="toURL('/login')">
                  {{ $t("message.regist_login") }}
                </a-button>
                <a-button v-else type="primary" shape="round" @click="goHome">
                  {{ $t("message.back_home") }}
                </a-button>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-layout-header>
  </div>

  <ChangePwdDialog :data="changePwdDialogData"  />
</template>

<script setup>
import { ref, reactive, onMounted, inject, watch } from 'vue';
import { useRouter } from 'vue-router';
import cache from '@/utils/cache';
import { useI18n } from 'vue-i18n';
import { logout, googleUserinfo } from '@/api/index';
import { toUrl, getLogined, clearLoginData, loginSuccess, isPhone, routerToUrl } from '@/utils/tool';
import ChangePwdDialog from '../ChangePwdDialog.vue';
import webConfig from '@/config/wenConfig';


const { locale, t } = useI18n();
const emit = defineEmits(["clickPhoneSearchUI"]);

let props = defineProps({
  isLogin: false,
  reload:false
});

let logined = ref(false);
let loginedData = reactive({ storage: {} });

watch(() => props.reload, (newValue, oldValue) => {
  //重新加载 昵称和邮箱
  loginedData.storage.email = cache.getEmail();
  loginedData.storage.loginName =  cache.getUserName();
});

const router = useRouter();//必须写在外面才行，如果在函数中定义调用，就会报错。

let langName = ref('English');
let langToName = new Map();
langToName.set("en", "English");
langToName.set("zh", "简体中文");
langToName.set("fr", "Français");
langToName.set("es", "Português");

let curMenu = ref('');
let visibleMenu = ref(false);

let showPhoneSerarchUI = ref(false);

let changePwdDialogData = reactive({
  show:false
});

let pageData = reactive({
  menuItems: [],
  minWidth: isPhone() ? '100%' : '1366px',
});



function goHome() {
  router.push({ path: '/' + webConfig.getCurrentLang(), query: {} });
}

function toURL(url) {
  //跳转到Flutter 路径 ，top +admin+ language + url 即可

  router.push({ path: '/' + webConfig.getCurrentLang() + url });
}

function changeMenu(item) {
  curMenu.value = item.path;
}

function gotoManager() {

  visibleMenu.value = false;
  toURL("/user");
}

function clickPhoneSearchUI() {
  emit("clickPhoneSearchUI");
}

function changePwd() {
  visibleMenu.value = false;
  changePwdDialogData.show = true;
}

async function userLogout() {
  visibleMenu.value = false;

  var result = await logout();
  // console.log(data);
  if (result) {
    //移除 邮箱和登录名
    clearLoginData();

    //跳转到登录页面
    toURL('/login');
  }
}


//切换语言
function changeLang(lang) {
  //切换语言为当前路径中的语言
  locale.value = lang;

  langName.value = langToName.get(lang);

  //保存当前语言
  cache.saveLang(lang);
  webConfig.currentLang = lang;

  let pathObj = router.currentRoute.value;

  let path = "";
//特例处理- /track/:code/:num 不用切换路径
  if(pathObj.path.indexOf('/track/') == 0)
  {
    router.push({ path: pathObj.path, query: {t: new Date().getTime()} });
  }
  else
  {
    if (pathObj.path.length > 3) {
      path = pathObj.path.substring(3);
    }

    // reload();
    router.push({ path: '/' + lang + path, query: pathObj.query });
  }

  initMenus();
}

function initMenus() {
  pageData.menuItems = [];

  if (logined.value) {

    pageData.menuItems.push({ name: t('message.menu_tracking_no_search'), path: '/user/search' });
    pageData.menuItems.push({ name: t('message.menu_history'), path: '/user/history' });

  }
  else {
    pageData.menuItems.push({ name: t('message.carrier_name'), path: '/carrierList' });
    pageData.menuItems.push({ name: t('message.plan_title'), path: '/plan' });
    pageData.menuItems.push({ name: t('message.menu_log'), path: '/log' });
    pageData.menuItems.push({ name: t('message.about_us'), path: '/aboutUS' });
  }
}

function oneTapLogIn(autoLogin) {
  googleSignIn(autoLogin, async (credential) => {
    //自动登录
    // print("Goolge JWT: $credential");
    //调用服务器验证接口，登录成功。
    var data = { "googleUserToken": credential };
    var data = await googleUserinfo(data);

    if (data) {
      let loginData = data;

      loginSuccess(loginData.email, loginData.userName);
      //刷下UI
      logined.value = true;
      loginedData.storage = {};
      loginedData.storage.email = loginData.email;
      loginedData.storage.loginName = loginData.userName;

      initMenus();

      //跳转到用户查询页面
      routerToUrl('/user/search');
    }

  });
}

onMounted(() => {
  // console.log(window.location.href);
  // console.log(router.currentRoute.value.params);
  var curLang = router.currentRoute.value.params["lang"];
  if(!curLang)
  {
    curLang = locale.value;
  }
  else if (langToName.has(curLang) == false) {
    curLang = "en";
  }

  webConfig.currentLang = curLang;

  showPhoneSerarchUI.value = window.location.href.indexOf('/result/') > -1;

  langName.value = langToName.get(curLang);

  // console.log(localLang + ' ' + curLang);
  if (locale.value != curLang) {
    changeLang(curLang);
  }

  if (isPhone() == false) {
    //登录状态数据
    logined.value = getLogined();

    if (logined.value) {
      loginedData.storage.email = cache.getEmail();
      loginedData.storage.loginName = cache.getUserName();
    }

    initMenus();

    //是否自动登录-首页才开启；登录页登录但不自动登录
    if (logined.value == false) {
      if (window.location.hash == ('#' + toUrl(""))) {
        oneTapLogIn(true);
      }
      else if (window.location.hash == ('#' + toUrl("/login"))) {
        oneTapLogIn(false);
      }

    }
  }

  if (logined.value == false) {
    if (window.location.href.endsWith('/log')) {
      curMenu.value = '/log';
    }
    else if (window.location.href.endsWith('/plan')) {
      curMenu.value = '/plan';
    }
    else if (window.location.href.endsWith('/aboutUS')) {
      curMenu.value = '/aboutUS';
    }
    else if (window.location.href.endsWith('/carrierList') || window.location.href.indexOf('/carrier/') > -1) {
      curMenu.value = '/carrierList';
    }
  }
  else {
    if (window.location.href.indexOf('/user/search') != -1) {
      curMenu.value = '/user/search';
    }
    else if (window.location.href.indexOf('/user/history') != -1) {
      curMenu.value = '/user/history';
    }

  }

});
</script>

<style scoped>
.ant-layout-header {
  height: 57px;
  padding: 0;
  line-height: 57px;
  color: black;
  background: white;
}

.div_header {

  display: flex;
  align-items: center;
  height: 57px;

}

.menuItem {
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.selectMenuItem {
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.userDiv {
  width: 260px;
  min-width: 230px;
  height: 293px;
  /* box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1); */
}

.cDiv {
  margin: 0 auto;
  width: 200px;
  height: 200px;
  background-color: pink;
}

.menuBtn {
  height: 44px;
  line-height: 44px;
  text-align: left;
  padding-left: 16px;
  /* padding-top: 15px;
  padding-bottom: 15px; */
  cursor: pointer;
}

.menuBtn:hover {
  background-color: #f5f5f5;
}

:deep(.ant-popover-inner-content ){
    padding: 0;
    color: rgba(0,0,0,.85);
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 0px;
  padding-right: 20px;
}

.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 50px;
}

.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {padding: 0px 0px;}

:deep(.ant-dropdown-menu-item,.ant-dropdown-menu-submenu-title)
 {
  clear: both;
  margin: 0;
  padding: 0px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.3s;
}

.dropdownRightIcon{
  font-size: 18px;
  color: #999999;
}

.dropdownDiv{
  color: black;
  cursor: pointer;
  display: flex;
}
</style>