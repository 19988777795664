export default {
	message: {
		title: "Rastreamento de pacotes globais",
		search: "Ai Track",
		carrier: "Identifier les transporteurs automatiquement",
		carrier_name: "Transportadoras",
		carrier_description:
			"O sistema identificará a transportadora de acordo com o número de rastreamento",
		auto_check: "Identificar automaticamente",
		clear_all: "Apagar tudo",
		select_transport_title: "Selecione uma transportadora",
		all_carrier: "Todas as transportadoras",
		all_airline: "Todas as aéreas",
		common_carriers: "Comuns",
		carrier_page_title: "Rastrear",

		not_found_order_no:
			"Nenhum pacote correspondente, verifique o número de rastreamento e a transportadora",
		order_no_has_shipment:
			"Nenhum resultado de rastreamento, verifique as informações do voo",

		network_error: "Erro na solicitação de rede",
		order_no_maybe_carriers:
			"O número de rastreamento pode pertencer a uma das seguintes transportadoras",
		your_order_no_exists_many_carriers:
			"Existem várias transportadoras possíveis para o seu número de rastreamento",
		tracking_result_title: "Resultados de rastreamento",
		air_tracking_result_title: "Informação de vôo",

		copy_link: "Copiar link",
		copy_detail: "Copiar detalhes",
		more_detail: "More information",
		end_logistics_number: "Last Mile Tracking No.",
		dispatcher: "Carrier",
		dispatcher_website: "Carrier website",
		phone: "Carrier Tel",
		more_detail_btn_tip: "No more information.",

		// menu
		menu_tracking_no_search: "Consulta a carta de porte",
		menu_history: "História de rastreamento",
		menu_log: "Registro de atualização",

		// /Result tab title

		tracking_list_empty: "Nenhum número de rastreamento correspondente",
		air_list_empty: "Sem dados de voo",

		email_code_send_success: "O código de verificação foi enviado",

		// /运输单号校验
		title_input_order_no:
			"Por favor, insira o número de rastreamento (7 a 50 dígitos)",
		tracking_no_duplicate: "Número de rastreamento duplicado",
		tracking_no_hint: "Insira o número de rastreamento, são permitidos até 20",

		copy_success: "Copiar com sucesso",

		normalCarriers: "Transportadoras logísticas",
		airCarriers: "Transportadoras aéreas",
		try_title: "Tentar: ",
		parcel_unit: "Pedaços",

		air_no: "Voo",
		air_start_end_station: "Estação de partida/estação de chegada",
		air_maybe_start_time: "Horário estimado de partida",
		air_maybe_end_time: "Horário estimado de chegada",
		air_start_time: "Horário de partida",
		air_end_time: "Horário de chegada",
		weight_unit: "Peso/Pedaços",

		// login
		login_title: "Login",
		login_register: "Registre uma conta >",
		login_button: "Login",
		login_forget_pwd: "Esqueceu a senha",
		login_password: "Senha",
		login_email: "Endereço de e-mail",
		remember_me: "Lembrar senha",

		// 忘记密码
		forgot_pwd_title: "Recuperar a senha",
		forgot_pwd_code: "Código de verificação",
		forgot_pwd_newPwd: "Nova Senha",
		forgot_pwd_confirmPwd: "Confirme a Senha",
		forgot_pwd_ok: "Certo",
		forgot_pwd_login: "login >",
		forgot_pwd_send_code: "Enviar",
		forgot_pwd_send_second: " segundos",

		// 修改密码
		change_pwd_title: "Mudar senha",
		change_pwd_old_pwd: "Senha original",
		change_pwd_old_pwd_empty: "A senha original não pode estar vazia",
		change_pwd_success: "Redefinição de senha concluída",
		change_pwd_button_ok: "Certo",

		// 注册用户
		register_title: "Registre uma conta",
		register_name: "Nome de usuário",
		register_ok: "Registrar agora",
		password_format_wrong:
			"8-16 caracteres de comprimento, conter pelo menos números e letras, não pode conter espaços",
		privacy_agreement_tip:'By registering, you agree to',
		privacy_agreement:'《Aitrack Privacy Policies》',
		user_email_empty: "O endereço de e-mail não pode ficar vazio",
		user_email_validate: "O formato do endereço de e-mail não está correto",
		user_name_empty: "O nome de usuário não pode ficar vazio",
		user_email_code_empty: "O código de verificação não pode ficar vazio",
		user_new_pwd_empty: "A senha não pode ficar vazia",
		user_confirm_pwd_empty: "Confirmação de senha não pode ficar vazio",
		user_pwd2_empty: "Duas senhas são inseridas de forma inconsistente",

		regist_login: "Registre-se/Login",
		back_home: "Voltar à página inicial",
		registration_agreement: "",
		synchronization_time: "Tempo sincronizado：",

		register_success: "Registre-se com sucesso",
		reset_pwd_success: "Redefinir senha com sucesso",

		// 日期组件
		date_dialog_today: "Hoje",
		date_dialog_week: "Última semana",
		date_dialog_month: "Último mês",

		// 请求code错误
		request_101_params_error: "Parâmetros de solicitação incorretos",
		request_103_page_params_error:
			"Parâmetros de consulta de paginação incorretos",
		request_104_no_permission: "Sem permissão de acesso",

		request_portal_102_login_name_exists: "O nome de login já foi usado",
		request_portal_105_email_exists: "O endereço de e-mail já foi usado",
		request_portal_106_code_exists: "Código de verificação errado",
		request_portal_107_login_fail: "Endereço de e-mail ou senha incorretos",
		request_portal_108_email_not_exists:
			"O endereço de e-mail não está registrado",
		request_portal_109_account_disabled: "A conta está desativada",
		request_portal_110_password_error: "A senha original está incorreta",
		request_portal_111_email_exists:
			"A conta de e-mail já existe, faça login diretamente por favor.",
		request_portal_112_password_format_wrong: "A senha original está incorreta",

		// 请求code错误-结束

		keyword_search_carriers: "Pesquisar operadoras usando palavra-chave",

		user_logout: "Sair",

		table_no: "Número de série",
		table_operate: "Ações",
		table_sort_asc: "",
		table_sort_desc: "",
		table_page_total: "",
		table_page_row: " no total",
		table_no_select: "Nenhuma linha selecionada",
		table_delete: "Apagar",
		table_delete_rows: "Tem certeza de que deseja excluir?",

		// /按钮文字
		button_confirm_text: "Confirme",
		button_ok_text: "Confirme",
		button_cancel_text: "Cancelar",

		// /页面-历史运单表格列名

		history_table_tracking_no: "Numero de rastreio",
		// 'history_table_carriers': '运输商',
		history_table_package_status: "Estado do pacote",
		history_table_event: "Evento",
		history_table_event_time: "Hora do evento",
		history_table_shipping_update_time: "Hora de atualização",
		history_table_update_time: "Hora de consulta mais recente",
		create_time: "Hora de criação",
		history_input_tracking_no_search:
			"Digite o número de rastreamento para consultar",

		// 页面-更新日志
		log_page_title:
			"Apoiamos mais de 100 transportadoras de logística transfronteiriça",
		log_page_description:
			"Apoiamos USPS, ROYALMAIL, DHL, GLS e outras operadoras, estamos simplificando e integrando o processo e continuamos estabelecendo parcerias com mais operadoras.",

		// 提示
		tooltip_click_copy: "Clique para copiar",

		// 首页运输商查询
		home_page_input_search_carrier: "Consulta da transportadora",

		// /用户运单查询-批量导入弹框
		user_search_page_dialog_import_batch: "Importação em lote",
		user_search_page_dialog_file_upload: "Clique para fazer upload do arquivo",
		user_search_page_dialog_file_format_incorrect:
			"O formato do arquivo importado não está correto",
		user_search_page_dialog_step1_click: "1.Clique",
		user_search_page_dialog_step1_download_template: " Baixar o modelo ",
		user_search_page_dialog_step1_end:
			"Por favor, preencha a lista de importação (verifique o código da operadora após a troca da folha)",
		user_search_page_dialog_step2:
			"2.A carta de porte duplicada será rastreada em conjunto, e o rastreamento original será coberto com o rastreamento mais recente, importe até 5.0000 cartas de porte de uma só vez.",
		user_search_page_dialog_step3:
			"3.As informações de rastreamento não serão atualizadas imediatamente após a importação do arquivo. Verifique o progresso do rastreamento no Histórico de rastreamento.",
		user_search_page_dialog_select_file:
			"Por favor, selecione o arquivo para carregar primeiro",
		user_search_page_dialog_upload_success:
			"O arquivo foi carregado com sucesso, você pode verificar o progresso do rastreamento no Histórico de rastreamento.",
		user_search_page_dialog_upload_fail: "A importação falhou",

		user_search_page_dialog_more_then_100:
			" importe até 1000 cartas de porte de uma só vez",

		query_fail_input_verification: "Insira as informações de verificação",

		// /运单查询页（查询结果页，用户查询页）
		tracking_page_input_post_code: "Insira o CEP de destino",
		tracking_page_select_date: "Selecione uma data de coleta para o pacote",
		tracking_page_input_phone:
			"Por favor, insira o número do celular do destinatário",
		tracking_page_input_dest: "Por favor, insira um destino",

		// /提交建议
		connect_us_title: "Contacte-nos",
		connect_us_samll_title: "Escolha suas sugestões para Aitrack：",
		connect_us_input_title: "Por favor, insira um nome de título",
		connect_us_title_name: "Nome do título",
		connect_us_title_name_length_2:
			"O nome do título deve conter pelo menos 2 caracteres",
		connect_us_input_suggest: "Por favor, insira suas sugestões",
		connect_us_your_suggest: "Suas sugestões",
		connect_us_your_suggest_length_10:
			"Sua sugestão deve conter pelo menos 10 caracteres",
		connect_us_your_email: "Seu email",
		connect_us_your_phone: "Seu número de celular",
		connect_us_your_submit_suggest: "Enviar a sugestão",

		connect_us_suggest1:
			"Queria sugerir Aitrack para desenvolver uma nova função",
		connect_us_suggest2: "Espero que o Aitrack adicione novas operadoras",
		connect_us_suggest3: "Queria relatar um bug",
		connect_us_suggest4: "Queria fazer negócios com aitrack",
		connect_us_suggest5: "Outros",

		research_btn: "Consultar novamente",

		// 批量导入成功结果展示
		import_excel_result_upload_success: "O arquivo foi carregado com sucesso",
		import_excel_result_success_count:
			"Número de cartas de porte bem-sucedidas：",
		import_excel_result_fail_count: "Número de cartas de porte com falha：",
		import_excel_result_success_rewrite: " guias já existem e serão cobertas",
		import_excel_result_error_format: " (Formato incorreto)",
		import_excel_result_view_fail_detail: "Verifique os detalhes da falha",
		import_excel_result_success_description:
			"você pode verificar o progresso do rastreamento no Histórico de rastreamento.",
		import_excel_result_table_column_row_number: "Número de linha da tabela",
		import_excel_result_table_column_fail_tracking_no:
			"Número das guias falhadas",
		import_excel_result_table_column_fail_reason: "Razão da falha",

		// Excel导入错误提示
		import_excel_result_validate_tracking_no:
			"O número de rastreamento é obrigatório",
		import_excel_result_validate_carrier_code:
			"Código da transportadora errado",
		import_excel_result_validate_post_code: "CEP de destino ausente",
		import_excel_result_validate_date: "Data de entrega ausente",
		import_excel_result_validate_phone:
			"Número do celular do destinatário ausente",
		import_excel_result_validate_dest: "Destino ausente",

		import_excel_ok: "Recebido, somos muito gratos por sua ajuda e apoio!",

		// /2022-04-08 后面追加新的翻译
		// /全部
		event_names_all: "Todo",
		event_names_all_des: "",

		// /未知
		event_names_unknown: "Desconhecido",
		event_names_unknown_des: "",

		// /未查询到
		event_names_not_found: "Não encontrado",
		event_names_not_found_des: "",

		// /运输途中
		event_names_in_transit: "Em trânsito",
		event_names_in_transit_des: "",

		// /到达待取
		event_names_arrival_for_pickup: "Esperando para pegar",
		event_names_arrival_for_pickup_des: "",

		// /投递失败
		event_names_delivery_failed: "Não entregue",
		event_names_delivery_failed_des: "",

		// /成功签收
		event_names_signed_successfully: "Entregue",
		event_names_signed_successfully_des: "",

		// /可能异常
		event_names_possible_exceptions: "Alerta",
		event_names_possible_exceptions_des: "",

		// /运输过久
		event_names_shipping_too_long: "Expirado",
		event_names_shipping_too_long_des: "",

		// /2022-04-11 后面追加新的翻译
		utc_0: "World time UTC+0",
		utc_1: "East First District UTC+1",
		utc_2: "East Second District UTC+2",
		utc_3: "East Third District UTC+3",
		utc_4: "East Fourth District UTC+4",
		utc_5: "East Fifth District UTC+5",
		utc_5_5: "East Fifth District UTC+5.5",
		utc_6: "East Sixth District UTC+6",
		utc_7: "East Seventh District UTC+7",
		utc_8: "East Eigith District UTC+8",
		utc_9: "East Ninth District UTC+9",
		utc_10: "East Tenth District UTC+10",
		utc_11: "East Eleventh District UTC+11",
		utc_12: "East Twelfth District UTC+12",
		"utc_-1": "West First District UTC-1",
		"utc_-2": "West Second District UTC-2",
		"utc_-3": "West Third District UTC-3",
		"utc_-4": "West Fourth District UTC-4",
		"utc_-5": "West Fifth District UTC-5",
		"utc_-6": "West Sixth District UTC-6",
		"utc_-7": "West Seventh District UTC-7",
		"utc_-8": "West Eigith District UTC-8",
		"utc_-9": "West Ninth District UTC-9",
		"utc_-10": "West Tenth District UTC-10",
		"utc_-11": "West Eleventh District UTC-11",
		"utc_-12": "West Twelfth District UTC-12",
		tracking_time_zone: "Tracking time zone",

		// /2022-04-13
		// /默认标题
		web_title: "Aitrack-Rastreamento de pacotes globais",
		web_title_carrier: "Aitrack-Todas as transportadoras",
		web_title_log: "Aitrack-Registro de atualização",
		web_title_user_history: "Aitrack-História de rastreamento",
		web_title_automatically: "Aitrack-Login",
		web_title_carrier_detail: "Aitrack-Rastreamento de pacotes globais",

		// /2022-05-07
		web_title_register: "Aitrack-Register an account",
		web_title_found_pwd: "Aitrack-Forgot password",
		web_title_guest_track: "Aitrack-Waybill tracking",
		web_title_user_track: "Aitrack-Waybill inquiry",

		// /2022-05-09
		web_title_description:
			"Aitrack is a global logistics packages tracking platform. Support USPS, Fedex, DHL, UPS, EMS, GLS and other carriers package tracking.",
		web_title_carrier_description:
			"Aitrack is a global logistics packages tracking platform. Support USPS, Fedex, DHL, UPS, EMS, GLS and other carriers package tracking.",
		web_title_log_description:
			"Aitrack has supported USPS, Fedex, DHL, UPS, EMS, GLS and other carriers package tracking, global cooperative carriers keep adding.",
		web_title_user_history_description:
			"Aitrack helps you track your package status anytime, anywhere.",
		web_title_automatically_description:
			"Welcome to Aitrack! We are committed to providing global users with one-stop inquiry services for international logistics packages.",
		web_title_carrier_detail_description:
			"Aitrack helps you track package status.",
		web_title_register_description: "",
		web_title_found_pwd_description: "",
		web_title_guest_track_description: "",
		web_title_user_track_description: "",

		// /2022-06-09
		user_manager_title: "Account management",
		other_type_login_title: "Bind your third party account",
		user_un_bind_title: "Unbound",
		user_bound_title: "Bound",
		user_remove_bind_title: "Unbind",
		user_info_validate_title: "Information verification",
		user_info_phone: "Cellphone",
		user_info_change_phone: "Change cellphone number",
		user_info_bind_phone: "Bind cellphone number",
		user_info_change_email: "Change email address",
		btn_save: "Save",
		send_email_code_more:
			"Please do not frequently request to send verification codes",

		// /2022-06-10
		change_email_old: "Old email verification",
		change_email_new: "New email verification",
		change_email_btn_next: "Next",

		"request_modify-email_106_error":
			"The verification code for previous email address is incorrect",
		"request_modify-email_107_error":
			"The verification code for new email address is incorrect",
		"request_modify-email_same_email_error":
			"The new email address cannot be the same as the previous one",
		bind_phone_title: "verification cellphone",
		un_validate_phone_title: "Unbind",
		phone_is_empty_title: "Cellphone number cannot be empty",
		validate_phone_error: "Incorrect verification code",
		please_send_code_email:
			"Please check your mailbox to get the verification code",
		validate_card: "Verify your email address",

		interface_security_title: "The key to access subscribe interface",
		reset_security_key: "Renew the key",
		security_key: "Key",

		reset_security_confirm:
			"If you renew the key, the previous key will be invalid. Please confirm if you still want to renew it ?",

		btn_search: "Inquiry",
		your_tracking_no: "Your tracking number",

		vip_tracking_page_url: "URL for your own tracking page",
		vip_tracking_page_url_description:
			"Copy the tracking page link and add it to your store's website",
		h5_not_found_title: "Sorry, the tracking number information was not found",
		h5_not_found_content:
			"Please check the tracking number information, unshipped waybill cannot be tracked",

		save_success: "Operation successfully",

		// /2022-06-23
		user_match_rule_title: "Custom event",
		user_match_rule_keywords_title: "Enter event name and search",
		btn_event_management: "Event management",
		// 'btn_new_match_rule': 'Add Custom Event',
		condition_rule_match_type: "Matching rules",
		condition_trajectory_rule: "Standardize activity",

		history_exist_trackingNo_carrierCode:
			"The carrier information of this Waybill already exists and cannot be changed",

		// /2022-06-27
		event_match_page_select_event: "Select event",
		event_match_page_event_manage: "Event management",
		event_match_page_info:
			"(Note: You can skip the status and search events directly)",
		event_match_page_view_all_event: "View all events",
		event_match_page_view_add_event: "Add custom event",
		event_match_page_view_edit_event: "Edit custom event",

		event_match_page_view_state_lv1: "Primary status",
		event_match_page_view_state_lv2: "Secondary status",
		event_match_page_view_event: "Event",

		event_match_page_view_state_lv1_name: "Primary status name",
		event_match_page_view_state_lv2_name: "Secondary status name",
		event_match_page_view_event_name: "Event name",
		event_match_page_view_status_title: "Enabled status",
		event_match_page_view_begin_date_title: "Valid start date",
		event_match_page_view_end_date_title: "Valid end date",

		event_match_page_add_matching_url_title: "Add rule",
		event_match_page_edit_matching_url_title: "Edit rule",
		waybill_status:"Status",
		additional_waybill_status: "Status",
		model_Tips:'Notice',
		tips_content:'The visitors are limited to a daily inquiry. You can login aitrack to check the waybill status and hope you a better experience in Aitrack. ',
		remark: "Remark",
		full_matching_rule: "Fully match",
		reg_matching_rule: "Regex match",
		matching_rule_title: "Matching rules",
		additional_matching_behavior:'extra action',
		overwrite_delivery_status:'overwrite delivered status',
		front_splicing_event_name:'prepend with event name',
		replace_with_event_name:'replace by event name',
		block_deletion:'delete',
		matching_rule_error_103: "Update failed",
		matching_rule_error_104: "The matching rule has already existed",
		matching_rule_error_105: "The matching rule is not available",

		error_105: "There was a problem with the service, please try again later",
		error_996: "Search failed, please ask customer service for help. ",

		event_match_page_view_begin_date_empty_title:
			"Please select valid start date",
		event_match_page_view_end_date_empty_title: "Please select valid end date",
		event_match_page_view_begin_end_date_error:
			"Valid end date cannot be earlier than the valid start date",

		event_match_page_test_matching: "Test rule",
		event_match_page_test_matching_title: "Regular expression rules test",
		event_match_page_test_trajectory_title: "Tracking information",
		event_match_page_test_input_trajectory_title:
			"Please enter tracking information",
		event_match_page_test_fail: "Track information doesn't match the rule",

		event_match_help:
			"Learning website: <a href='https://www.runoob.com/regexp/regexp-syntax.html' target='_blank'>https://www.runoob.com/regexp/regexp-syntax.html< /a><br/>Simple example:<br/>^ABC matches messages starting with ABC<br/>^ABC$ matches messages ending with ABC<br/>^AB C$ matches messages starting with AB,  ending with C<br/>AB.{0,30} matches messages starting with AB and ending with any character from 0 to 30<br/>.*AB.* matches messages starting with any character, including AB, and ending with any character <br/>",

		// /2022-07-05
		event_info_save_102: "Event name already exists",

		// /2022-07-06
		carrier_full_name: "Carrier name",
		table_edit: "Edit",
		status: "Status",
		status_open: "Enable",
		status_close: "Disable",

		event_match_page_test_success:
			"Track information matches the rule successfully",

		// /2022-07-07
		user_page_timezone_title: "Time display for tracking event",
		user_page_timezone_item_carrier_timezone: "Display by carrier's time zone",
		user_page_timezone_item_user_timeone: "Display by your time zone",

		page_size: "Page size",
		page_jump: "Jump page",

		// /2022-07-20
		user_bi_analysis_page_title: "Delivery analysis",
		all_carrier_title: "All carriers",
		all_country_title: "All countries",

		day_title: "Day",
		week_title: "Week",
		month_title: "Month",

		day: "day", // 单数
		days: "days", // 复数

		quantile_title: "quantile",
		destination_country_title: "Destination country",
		query_time_range_title: "Time horizon",
		tt_days_title: "Due days",
		ntt_days_title: "Shipping so far",
		temp_kpi_title: "Temporary KPI",

		kpi_manage_title: "Standard KPI",
		otd_analysis_title: "On-Time-Delivery analysis",
		otd_tt_analysis_title: "Delivered",
		otd_utt_analysis_title: "Out of delivery",

		standard_title: "Over the benchmark",
		un_standard_title: "Below the benchmark",
		proper_investment_title: "Delivery status",
		delivery_success_rate_title: "Delivery rate",
		proportion_of_investment_days_title: "Duration proportion",
		details_of_completed_investment_title: "Delivered details",
		failure_rate_title: "Failure rate",
		details_of_incomplete_investment_title: "Details of shipping packages",

		proportion_of_up_to_standard_title: "Benchmark percents",
		time_for_reaching_the_standard_title: "Quantile values",
		kpi_all_title: "All KPI",
		kpi_standard_title: "Standard KPI",
		kpi_temporary_title: "Temporary KPI",

		please_select_query_time_range_title:
			"Please enter the start date and end date.",
		query_time_range_end_less_start_title:
			"The end date you entered occurs before the start date.",
		query_days_15_title: "Query has surpassed the 15-day horizon.",
		query_weeks_15_title: "Query has surpassed the 15-week horizon.",
		query_months_15_title: "Query has surpassed the 15-month horizon.",

		temporary_kpi_rate_0_100_title: "Quantile range of temporary KPI: 0-100",

		unsuccessful_delivery_orders_title: "Number of shipping packages",
		unsuccessful_bid_rate_title: "Failure rate",

		successful_delivery_orders_title: "Number of delivered packages",
		successful_bid_rate_title: "Delivery rate",

		country_title: "Country",
		kpi_type_title: "Type of KPI",
		avg_score_title: "Avg quantile",
		avg_time_title: "Avg duration",
		delay_title: "delay",
		delay_more_9_days_title: "Delay of 10 and more days",

		total_votes_title: "Total",
		delivered_votes_title: "Delivered",
		un_delivered_votes_title: "Undelivered",
		delay_votes_title: "Delayed",
		shipped_orders_title: "Number of delivered packages",
		proportion_title: "Proportion",

		total_number_of_waybills_including_undelivered_waybills:
			"Total(include undelivered)",
		total_title: "Accumulation",
		total_tracking_no: "Total",
		unshipped_orders_title: "Undelivered",

		// /标准KPI管理
		kpi_standard_quantile_title: "Quantile",
		quantile_compliance_days: "KPI(days)",
		dialog_standard_KPI_management_title: "Standard KPI management",
		add_kpi: "Add KPI",
		edit_kpi: "Edit KPI",
		import_103: "Please download the latest template.",

		please_input_kpi_quantile_title: "Please enter the quantile.",
		kpi_quantile_0_100_title: "Quantile range of KPI: 0-100",
		please_select_carrier_title: "Please select a carrier.",
		please_select_quantile_day_title: "Please select a benchmark.",

		select_country_title: "Select country",
		please_select_country_title: "Please select country.",
		kpi_quantile_title: "KPI quantile",
		quantile_day_title: "KPI(days)",
		time_range_title: "Time horizon",

		waybill_collection_time: "Waybill collection time",

		within_title: "Within", // 1天内
		more_than_title: "more than", // 1天以上

		country_origin: "The country of origin",
		destination_country: "The country of destination",
		origin_title: "Origin",
		destination_title: "Destination",

		airline_title: "Airline",
		airline_master_number: "Airline master waybill number",
		tracking_number_information: "The tracking number information",
		view_more_tracking_information: "Show more tracking details",

		no_tracking_data: "No tracking result",

		// /2022-07-27
		please_select_1_item: "Please select at least one",
		recipient_phone: "The recipient phone number",
		recipient_email: "The recipient email",
		export_otd_title: "Export delayed & undelivered waybill list",
		select_export_otd_kpi_type_title: "Select the KPI",
		export_delivered: "Export delivered waybill list",
		export_un_delivered: "Export undelivered waybill list",

		// /2022-08-01
		page_user_title: "Basic setting",
		page_user_member_title: "Member account",
		page_user_subscription_title: "My subscription",
		amount_title: "Account",

		tooltip_title: "Note",
		delete_confirm_title: "Delete ",
		add_member: "Add member",
		edit_member: "Edit",
		setting_pwd: "Password",
		auto_pwd: "Auto create",
		define_pwd: "Setting",
		tooltip_pwd:
			"*After the user is successfully created, the account password is sent to the email address by default, and the password can be changed after logging in",

		ddl_clear_all: "Clear",

		select_tt_days_title: "Choose several due days",
		select_ntt_days_title: "Choose several horizons of shipping so far",
		"8_20_length": "8-20 characters",

		request_portal_115_not_activated_wrong:
			"The account is not activated, please contact the staff",
		kpi_exists_override:
			"This standard KPI already exists, do you want to override it ?",

		recharge: "Top-up",
		subscription: "Spend",
		free: "Free",
		deduction: "Deduct",
		date: "Date",
		quota: "Subscription amount",
		type: "Type",
		quota_validity_period: "Valid date",
		remaining_query_quota: "Balance",
		please_choose: "Please select ",
		please_input: "Please enter ",
		not_empty: " is required",
		email: "Email",

		clear: "Clear",

		save_successful: "Saved",
		delete_successful: "Deleted",

		// /2022-08-11
		user_notify_center_title: "Notification center",
		page_user_notify_task_title: "Notification task",
		page_user_notify_template_title: "Notification template",
		add: "Add",

		task_name: "Task",
		trigger_task_event: "Trigger event",
		notify_type: "Channel",
		mail: "Email",
		sms: "SMS",
		notify_event_type: "Trigger event level",
		update_time: "Modification date",
		add_task: "Add task",
		edit_task: "Edit task",

		task_tooltip: "Attention: All sent as default.",
		template: "Template",
		preview: "Preview",
		template_name: "Template name",

		email_template: "Email template",
		add_email_template: "Add email template",
		edit_email_template: "Edit email template",

		sms_template: "SMS template",
		add_sms_template: "Add SMS template",
		edit_sms_template: "Edit SMS template",

		template_desc: "Template description",

		view_url: "Click here to check more waybill details",

		template_content: "Content",

		view: "View",
		edit: "Edit",

		// 2022-08-15
		send_test_sms: "Send test SMS",
		send_test_email: "Send test email",
		send_test_sms_target: "Send test SMS to",
		send_test_email_target: "Send test email to",

		send_test_sms_tooltip:
			"Test SMS that you sent in success will expend your subscription.",
		send_test_email_tooltip:
			"Test email that you sent in success will expend your subscription.",
		send_successful: "Send successfully",

		view_sms_template: "View test SMS",
		view_email_template: "View test email",

		email_subject: "Theme content",

		api_trackings_109:
			"Sorry to tell you that the waybill tracking service is invalid since your subscription has been used up.",
		api_trackings_110:
			"Sorry to tell you that the waybill tracking service is invalid since your subscription has been expired.",

		recommend: "Recommend",
		customize: "Customize",

		exists_template_name: "Duplicated template name",
		exists_task_name: "Duplicated task name",
		join_task_template: "Fail to delete. The template is in use",

		// /2022-08-18
		template_language: "Template language",
		EN: "EN",
		CN: "CN",
		ALL: "ALL",

		user_page_callback_url: "Subscribe callback service", // 订阅回调
		user_page_callback_url_tip:
			"Specify the callback url as you have add callback service. AiTrack will be invoked to pushing the latest tracking data through the API",
		callback_url: "Callback url",
		callback_url_error: "The format of callback url is incorrect.",

		balance_detail: "Stored value",
		subscribe_detail: "Subscription balance",
		balance_yuan: "Stored value",
		operate_time: "Operate time",
		balance_recharge: "Top-up",
		money_yuan: "Expense",

		sms_pay: "SMS consumption",
		change_add: "Increment",
		change_reduce: "Reduction",

		// /2022-08-25
		page_sms_service_title: "SMS service",
		page_sms_service_description:
			"It can provide you with customized SMS notifications, notify recipients to pick up or handle exceptions, etc., to quickly improve the quality of customer service",
		page_sms_service_note:
			"Reminder: Price of SMS may change as supplier varies price without any extra notification .",
		telecom_operators: "Telecommunications suppliers",
		sms_price: "Price (CNY/Message)",
		sms_price_download: "Download the SMS pricing sheet",
		sms_template_note:
			"Reminder: Please pay attention to the content of the SMS template. If the template content is too long, multiple charges may be charged. English letters and numbers are limited to 160 characters per SMS, and the content of SMS containing non-English letters or special characters will be downgraded to 70 characters per SMS. After the limit is exceeded, the content can be sent normally, but an additional SMS fee will be charged. For specific charges, please refer to the SMS sending record page.",
		page_shop_title: "My shops",
		page_shop_tooltip: "Up to 10 shops can be added",
		add_shop: "Add",
		edit_shop: "Edit",
		shop_name: "Shop name",
		shop_type: "Shop type",
		shop_url: "Shop address",
		shop_status: "Shop status",
		shop_delete_title:
			"After deletion, all the waybills in the shop will be cleared and cannot be recovered. Are you sure you want to delete the shop?",
		shop_disable:
			"After deactivation, you will not be able to add a waybill, and the existing waybill will no longer be synchronized with the track. Are you sure you want to deactivate?",
		import_data: "Import",
		synchronous_data: "Sync",
		synchronous_days_range: "Time horizon of imported data",
		shop_save_104: "Parameter is invalid",
		shop_save_105: "The number of shop exceeds the limit",
		shop_save_106: "Sync time exceeds limit",
		shop_save_107: "Shop name is repeated",
		synchronous_data_tooltip:
			"The waybill will be imported within the time range, and the new waybill will be automatically and continuously synchronized. The first synchronization may last from a few minutes to a few days.",
		page_user_sms_detail_title: "SMS expense detail",
		send_time: "Sending time",
		send_status: "Sending status",
		successful: "Succeed",
		failure: "Failed",
		send_country: "Country",
		received_phone: "Phone",
		count_sms: "Quantity of billed SMS",
		money_type: "Currency",
		fee: "Expense",
		with_tracking_no: "Related order number",
		trigger_event_name: "Trigger event",
		between_30_days: "Within 30 days",

		synchronous_now: "Sync",
		api_trackings_108: "Importing data is not allowed for deactivated shop.",
		shop_sync_108: "Data synchronization is not allowed for deactivated shop.",

		page_api_title: "API configuration",
		add_api: "Add API",
		edit_api: "Edit API",
		api_nick_name: "API alias",
		connect_special_api: "Connect to dedicated API",
		URL: "URL",
		language: "Language",
		front_btn: "Previous",
		api_save_105: "The API alias already exists.",
		api_save_106: "The API configuration already exists.",

		enable_url: "Valid URL",
		disable_url: "Invalid URL",
		invalid_query: "Invalid query",
		subscribeBalance_0:
			"Your subscription has been used up, please contact customer service to recharge.",
		insufficient_subscribeBalance: "Insufficient quota",

		api_trackings_101: "Please enter at least one tracking number",
		api_trackings_102: "Too many waybills",
		api_trackings_108_2: "Quota used up",
		wait_query: "Pending",
		money: "CNY",
		api_event_info_delete_105:
			"Delete failed: We couldn't delete the event in use. Please check it in custom event.",
		api_event_info_delete_108:
			"Delete failed: We couldn't delete the event in use. Please check it in notification task.",
	},
};
